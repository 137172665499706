<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar flat color="white" dense>
      <v-toolbar-title>CV TYPE</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <v-data-table
      :headers="headers"
      :items="cv_type_list"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <span style="width: 20px"> </span>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on"
                >Add CV Type</v-btn
              >
            </template>
            <v-card>
              <v-form v-model="valid">
                <v-card-title>
                  <v-row justify="space-between">
                    <span class="headline">{{ formTitle }}</span>
                    <v-btn icon dark @click="dialog = false">
                      <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                  </v-row>
                  <!-- <span class="headline">{{ formTitle }}</span> -->
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <!-- <v-col cols="12" sm="12" md="12" v-if='editedIndex == -1'>
                                                <v-autocomplete :items="address_type_master_list" class="pt-2 pb-1"  v-model="addedItem.address_type_master_list" label="Select Address Type" :rules="[v => !!v || 'required']"  persistent-hint
                                                :hint="editedIndex==-1?'For example :: Local, Permenant':'For example :: Local, Permenant'"></v-autocomplete>
                                            </v-col> -->
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.type"
                          label="Enter CV Type"
                          :rules="[(v) => !!v || 'required']"
                          persistent-hint
                          :hint="
                            editedIndex == -1
                              ? 'For example :: Testing, Development, Networking , Hardware'
                              : 'For example :: Testing, Development, Networking , Hardware'
                          "
                        >
                        </v-text-field>
                      </v-col>
                      <!-- <v-col cols="12" v-if='editedIndex != -1'>Address Type
                                                <v-select dense v-model="editedItem.addressType" class="pt-2 pb-1"  :items="address_type_master_list" item-value="id" persistent-hint  :error="errorMsg.addressType?true:false" :error-messages="errorMsg.addressType?'Required':''"  persistent-hint
                                                :hint="editedIndex!=-1?'For example :: Local, Permenant':'For example :: Local, Permenant'"></v-select>
                                            </v-col> -->

                      <v-col cols="12" sm="6" md="4">
                        <v-checkbox
                          dense
                          v-model="editedItem.is_active"
                          color="success"
                          label="Active"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" outlined text @click="close"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="primary darken-1"
                    dark
                    @click="save"
                    :disabled="!valid"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.is_active="{ item }">
        <v-switch
          v-model="item.is_active"
          :labels="{ checked: 'On', unchecked: 'Off' }"
          @change="activeswitch(item)"
        ></v-switch>
      </template>

      <template v-slot:item.action="{ item }">
        <div>
          <v-avatar style="" size="25" class="edit-avatar">
            <v-icon class="edit-v-icon mr-2" @click="editItem(item)">
              edit
            </v-icon>
          </v-avatar>
          <v-btn text>
            <v-icon color="red" @click="deleteCVType(item.id)">
              mdi-delete</v-icon
            ></v-btn
          >
        </div>
      </template>
    </v-data-table>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    valid: false,
    overlay: false,
    errorMsg: {
      type: false,
    },
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    dialog: false,
    cv_type_list: [],
    headers: [
      {
        text: "CV Type",
        align: "left",
        sortable: false,
        value: "type",
      },
      {
        text: "Active",
        align: "left",
        sortable: true,
        value: "is_active",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],

    editedIndex: -1,
    editedItem: {
      id: "",
      type: "",
      is_active: true,
    },
    defaultItem: {
      addressType: "",
      is_active: true,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New CV Type" : "Edit CV Type";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    overlay(val) {
      val = () => {
        this.overlay = true;
      };
    },
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    initializeErrorMsgFalse() {
      this.errorMsg.type = false;
    },
    onLoad() {
      this.overlay = true;
      axios

        .post("/AdminMaster/adminCVType")

        .then((res) => {
          if (res.data.msg == "200") {
            //window.alert(res.data.msg)

            if ((this.load = true)) {
              this.overlay = false;
              this.cv_type_list = res.data.cv_type_list;
            }
          } else {
            window.console.log(res.data.msg);
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          //window.console.log(error);
        })
        .finally(() => {
          "use strict";
          var overlay = false;
        });
    },
    editItem(item) {
      this.editedIndex = this.cv_type_list.indexOf(item);
      // window.alert(`Index = ${this.editedIndex}`)
      this.editedItem = Object.assign({}, item);
      //  window.alert(`id = ${this.editedItem.id},type=${this.editedItem.type} ,is_active=${this.editedItem.is_active}`)
      this.dialog = true;
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      this.initializeErrorMsgFalse();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        if (this.isValidated()) {
          axios
            .post("/AdminMaster/editAdminCVType", this.editedItem)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                //window.console.log(res.data.msg)
                Object.assign(
                  this.cv_type_list[this.editedIndex],
                  this.editedItem
                );

                this.showSnackbar("#4caf50", "CV Type Updated Successfully..."); // show snackbar on success
              } else if (res.data.status.code == "NA") {
                //window.alert(res.data.status.code)
                this.showSnackbar("#b71c1c", "CV Type Already Present!!!"); // show snackbar on error
              }
            })
            .catch((error) => {
              window.console.log(error);
            });
          this.close();
        }
      } else {
        //this.address_type_list.push(this.editedItem)
        axios
          .post("/AdminMaster/saveAdminCVType", this.editedItem)
          .then((res) => {
            if (res.data.status.code == "SUCCESS") {
              // console.log("save in");
              //window.alert(res.data.status.code)
              this.cv_type_list.push(this.editedItem);
              this.showSnackbar("#4caf50", "CV Type Added Successfully!!!"); // show snackbar on success
              this.onLoad();
            } else if (res.data.status.code == "NA") {
              //window.alert(res.data.status.code)
              this.showSnackbar("#b71c1c", "CV Type Already Present!!!"); // show snackbar on error
            }
          })
          .catch((error) => {
            window.console.log(error);
          });
        this.close();
      }
    }, //save()
    isValidated() {
      if (this.editedItem.type) {
        return true;
      } else {
        if (!this.editedItem.type) {
          this.errorMsg.type = true;
        }

        return false;
      }
    }, // .....end of isValidated()
    deleteCVType(item) {
      const data = {
        type: item,
      };
      axios
        .post("/AdminMaster/deleteAdminCVType", data)
        .then((res) => {
          //console.log("a")
          if (res.data.msg == "200") {
            this.showSnackbar("#4caf50", "Deleted Successfully...");
            this.onLoad();
          } else {
            //error
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    activeswitch(item) {
      const data = {
        activateitem: item.id,
      };

      axios
        .post("/AdminMaster/activeAdminCVType", data)
        .then((res) => {
          if (res.data.msg == "200") {
            //  console.log("SUCCESS");
            this.showSnackbar("#4caf50", res.data.message);
          } else {
            //console.log("fail");
            this.showSnackbar("#b71c1c", "Something Wrong With Server");
          }
          this.onLoad();
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
</style>
