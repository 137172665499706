import { render, staticRenderFns } from "./LearnerCVType.vue?vue&type=template&id=49df989e&scoped=true"
import script from "./LearnerCVType.vue?vue&type=script&lang=js"
export * from "./LearnerCVType.vue?vue&type=script&lang=js"
import style0 from "./LearnerCVType.vue?vue&type=style&index=0&id=49df989e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49df989e",
  null
  
)

export default component.exports